import {
  CreateInfoWrapper,
  CreateLevel,
  CreateLevelHeader,
  InfoContent,
  InputTextErrorMsg,
  InputTextLabel,
  RequiredLabel,
} from './CreateInfo.styled';
import React, { useContext, useState } from 'react';

import CreateAdmin from '../createAdmin/CreateAdmin';
import FileUpload from '@components/molecules/fileUpload/FileUpload';
import { Heading } from '@components/atoms/heading/Heading';
import InputDropdownSearch from '@components/atoms/input/inputDropdownSearch/InputDropdownSearch';
import { InputText } from '@components/atoms/input/inputText/InputText';
import PropTypes from 'prop-types';
import SelectCreateTags from '@components/molecules/selectCreateTags/SelectCreateTags';
import SelectTags from '@components/molecules/selectTags/SelectTags';
import SelectTools from '@components/molecules/selectTools/SelectTools';
import Separator from '@components/atoms/separator/Separator';
import String from '@components/atoms/string/String';
import StringsContext from '@context/StringsContext';

const CreateInfo = props => {
  const { createInfoFormState, setCreateInfoFormState, createType } = props;
  const stringsContext = useContext(StringsContext);

  const [createInfoState, setCreateInfoState] = useState({
    industriesError: <String id="at_least_one_industry" />,
    toolsError: <String id="at_least_one_tool" />,
    titleError: <String id="title_required" />,
    durationError: <String id="enter_duration" />,
    validDurationError: <String id="enter_valid_duration" />,
    levelError: <String id="select_level" />,
    tutorialTypeError: <String id="select_type_tutorial" />,
    articleTypeError: <String id="select_type" />,
    articleSectionError: <String id="select_section" />,
  });

  const onLevelChange = value => {
    const updatedState = { ...createInfoFormState };
    updatedState.selectedLevel = value;
    updatedState.levelIsError = false;
    setCreateInfoFormState(updatedState);
  };

  const onTutorialTypeChange = value => {
    const updatedState = { ...createInfoFormState };
    updatedState.selectedTutorialType = value;
    updatedState.tutorialTypeIsError = false;
    setCreateInfoFormState(updatedState);
  };

  const onArticleTypeChange = value => {
    const updatedState = { ...createInfoFormState };
    updatedState.selectedArticleType = value;
    updatedState.articleTypeIsError = false;
    setCreateInfoFormState(updatedState);
  };

  const onArticleSectionChange = value => {
    const updatedState = { ...createInfoFormState };
    updatedState.selectedArticleSection = value;
    updatedState.articleSectionIsError = false;
    setCreateInfoFormState(updatedState);
  };

  const onToolsSelect = index => {
    const updatedToolsList = JSON.parse(
      JSON.stringify(createInfoFormState.toolsList)
    );

    updatedToolsList[index].isSelected = !updatedToolsList[index].isSelected;
    setCreateInfoFormState({
      ...createInfoFormState,
      toolsList: updatedToolsList,
      toolsIsError: false,
    });
  };

  const onCreateAdminFormChange = e => {
    props.setCreateAdminFormState({
      ...props.createAdminFormState,
      ...e,
    });
  };

  const onIndustriesSelect = index => {
    const updatedIndustriesList = JSON.parse(
      JSON.stringify(createInfoFormState.industriesList)
    );
    updatedIndustriesList[index].isSelected = !updatedIndustriesList[index]
      .isSelected;
    setCreateInfoFormState({
      ...createInfoFormState,
      industriesIsError: false,
      industriesList: updatedIndustriesList,
    });
  };

  const onAssetTypeSelect = asset => {
    const updatedState = { ...createInfoFormState };
    updatedState.selectedAssetType = asset;
    updatedState.assetTypesIsError = false;
    setCreateInfoFormState(updatedState);
  };

  const onInputChange = e => {
    const { value } = e.target;
    const { name } = e.target;
    const updatedState = { ...createInfoFormState };

    if (name === 'title' && value.length >= 240) return;
    if (name === 'duration' && value.length >= 10) return;

    updatedState[name] = value;

    if (name === 'duration') {
      if (parseInt(value) <= 0 || value.substring(0, 1) === '-') {
        updatedState[name] = '';
      } else {
        updatedState[name] = value;
      }
    }

    if (name === 'title' && value !== '') {
      updatedState.titleIsError = false;
    }
    if (name === 'duration') {
      if (value !== '' && parseInt(value) > 0) {
        updatedState.durationIsError = false;
      } else {
        updatedState.durationIsError = true;
      }
    }

    setCreateInfoFormState(updatedState);
  };

  const dataLevel = {
    label: <String id="create_information_field_tutorial_level_label" />,
    placeholder:
      stringsContext.create_information_field_tutorial_level_placeholder.field,
    onSelect: onLevelChange,
    value: createInfoFormState.selectedLevel,
  };

  const dataTutorialType = {
    label: <String id="create_information_field_tutorial_type_label" />,
    placeholder:
      stringsContext.create_information_field_tutorial_type_placeholder.field,
    onSelect: onTutorialTypeChange,
    value: createInfoFormState.selectedTutorialType,
  };

  return (
    <CreateInfoWrapper>
      <InfoContent>
        <Heading level="h2">
          <String id="create_information_title" />
        </Heading>
        <InputText
          onChange={onInputChange}
          type="text"
          inputShape="normal"
          name="title"
          required
          value={createInfoFormState.title}
          label={<String id="create_information_field_title" />}
          css={{ marginBottom: '$24' }}
          error={createInfoState.titleError}
          isError={createInfoFormState.titleIsError}
          inputTheme="transparent"
        />

        {createType !== 'asset' && (
          <SelectTags
            onToggleButtonClick={onIndustriesSelect}
            list={createInfoFormState.industriesList}
            label={<String id="create_information_field_industries" />}
            css={{ marginBottom: '$32', width: '100%' }}
            required
            isError={createInfoFormState.industriesIsError}
            error={createInfoState.industriesError}
          />
        )}
        {createType === 'asset' && (
          <CreateLevel css={{ zIndex: 4 }}>
            <CreateLevelHeader>
              <InputTextLabel isError={createInfoFormState.assetTypesIsError}>
                <String id="create_information_field_asset_type" />
              </InputTextLabel>
              <RequiredLabel
                isError={createInfoFormState.assetTypesIsError}
                size="12"
              >
                Required
              </RequiredLabel>
            </CreateLevelHeader>
            <InputDropdownSearch
              onSelect={onAssetTypeSelect}
              value={createInfoFormState.selectedAssetType || null}
              options={createInfoFormState.assetTypesList}
              css={{ marginBottom: '$32' }}
              isError={createInfoFormState.assetTypesIsError}
              placeholder="Select asset type"
            />
            {createInfoFormState.assetTypesIsError && (
              <InputTextErrorMsg
                isError={createInfoFormState.assetTypesIsError}
              >
                {createInfoState.assetTypesError}
              </InputTextErrorMsg>
            )}
          </CreateLevel>
        )}

        {createType === 'tutorial' && (
          <>
            <CreateLevel css={{ zIndex: 4 }}>
              <CreateLevelHeader>
                <InputTextLabel isError={createInfoFormState.levelIsError}>
                  {dataLevel.label}
                </InputTextLabel>
                <RequiredLabel
                  isError={createInfoFormState.levelIsError}
                  size="12"
                >
                  Required
                </RequiredLabel>
              </CreateLevelHeader>
              <InputDropdownSearch
                {...dataLevel}
                value={createInfoFormState.selectedLevel || null}
                options={createInfoFormState.levelOptions}
                css={{ marginBottom: '$32' }}
                isError={createInfoFormState.levelIsError}
              />
              {createInfoFormState.levelIsError && (
                <InputTextErrorMsg isError={createInfoFormState.levelIsError}>
                  {createInfoState.levelError}
                </InputTextErrorMsg>
              )}
            </CreateLevel>

            <CreateLevel css={{ zIndex: 3 }}>
              <CreateLevelHeader>
                <InputTextLabel
                  isError={createInfoFormState.tutorialTypeIsError}
                >
                  {dataTutorialType.label}
                </InputTextLabel>
                <RequiredLabel
                  isError={createInfoFormState.tutorialTypeIsError}
                  size="12"
                >
                  Required
                </RequiredLabel>
              </CreateLevelHeader>

              <InputDropdownSearch
                {...dataTutorialType}
                value={createInfoFormState.selectedTutorialType || null}
                options={createInfoFormState.tutorialTypeOptions}
                css={{ marginBottom: '$32' }}
                isError={createInfoFormState.tutorialTypeIsError}
              />
              {createInfoFormState.tutorialTypeIsError && (
                <InputTextErrorMsg
                  isError={createInfoFormState.tutorialTypeIsError}
                >
                  {createInfoState.tutorialTypeError}
                </InputTextErrorMsg>
              )}
            </CreateLevel>
            <InputText
              onChange={onInputChange}
              type="number"
              inputShape="normal"
              name="duration"
              value={createInfoFormState.duration}
              label="Duration (minutes)"
              css={{ marginBottom: '$32' }}
              error={createInfoState.durationError}
              isError={createInfoFormState.durationIsError}
              required
              inputTheme="transparent"
              min={1}
            />
          </>
        )}

        <SelectTools
          maxTools={12}
          toolsList={createInfoFormState.toolsList}
          onToolsSelect={onToolsSelect}
          label={<String id="create_information_field_tools" />}
          required
          error={createInfoState.toolsError}
          isError={createInfoFormState.toolsIsError}
          css={{
            marginBottom: '$32',
            width: '100%',
            position: 'relative',
            zIndex: 3,
          }}
        />

        {/* <SelectCreateTags
          maxTags={5}
          createTagsList={createInfoFormState.createTagsList}
          onCreateTagsSelect={onCreateTagsSelect}
          label={<String id="create_information_field_Tags" />}
          css={{ zIndex: 2 }}
        /> */}
        <Separator css={{ marginTop: '$64' }} />
        {props.showAdminSettings && (
          <CreateAdmin
            createType={createType}
            createAdminFormState={props.createAdminFormState}
            onFormChange={onCreateAdminFormChange}
          />
        )}
      </InfoContent>
    </CreateInfoWrapper>
  );
};

CreateInfo.propTypes = {
  createInfoFormState: PropTypes.object.isRequired,
  setCreateInfoFormState: PropTypes.func.isRequired,
  showAdminSettings: PropTypes.bool,
  // createType: PropTypes.oneOf(['artwork', 'tutorial', 'article']),
};

export default CreateInfo;
